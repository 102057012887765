<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">案場狀態</span>
      </div>
      <b-row class="borderColor">
        <div style="width: 100%">
          <div
            style="
              display: flex;
              align-items: center;
              margin: 10px;
              flex-wrap: wrap;
            "
          >
            <div class="inputDiv">
              <i class="fas fa-search mr-2"></i
              ><input
                type="text"
                name="filter"
                id=""
                v-model="searchInput"
                class="mr-2 filterInput"
                size="sm"
                :placeholder="'依' + selectValue.name + '搜尋'"
              />
              <span class="focus-border"></span>
            </div>
            <select style="margin-right: 10px" v-model="selectValue">
              <option v-for="(i, index) in filterTitle" :key="index" :value="i">
                {{ i.name }}
              </option>
            </select>
            <div style="margin-left: 10px">
              <span style="font-weight: 700; font-size: 16px; margin-right: 5px"
                >依區域篩選:
              </span>
              <select style="margin-right: 10px" v-model="filterSite">
                <option
                  v-for="(i, index) in filterSiteArray"
                  :key="index"
                  :value="i.value"
                >
                  {{ i.name }}
                </option>
              </select>
            </div>
            <div style="margin-left: auto">
              <button
                class="confirmButton"
                style="margin: 0px 5px 0px 0px; width: 120px; padding: 2px"
                @click="goMoxaPage"
              >
                MOXA頁面
              </button>
            </div>
          </div>
          <div
            style="
              margin: 2px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p
              style="
                margin: 0px;
                padding: 0px;
                font-weight: 700;
                font-size: 18px;
              "
            >
              數量統計: {{ factoryStatusFilterSearch.length }}
            </p>
            <button
              class="confirmButton"
              style="margin: 0px 5px 8px 0px; width: 80px; padding: 2px"
              @click="getRecentalarms"
              v-if="!loadingData"
            >
              更新
            </button>
          </div>
          <b-overlay :show="loadingData">
            <div
              style="overflow-x: auto"
              :style="minHeight"
              class="othertableStyle"
            >
              <table class="rwdTable">
                <tr>
                  <th
                    v-for="(i, index) in thTitle"
                    :key="index"
                    class="sort"
                    :class="{ down: !i.sort, up: i.sort }"
                    @click="sortHistoryData(i.sortName, index)"
                  >
                    {{ i.name }}
                  </th>
                  <th>設定</th>
                </tr>
                <tr v-for="(i, idx) in factoryStatusFilterSearch" :key="idx">
                  <td>{{ i.factoryName }}</td>
                  <td>{{ i.projectNumber }}</td>
                  <td>{{ i.county }}</td>
                  <td>{{ i.maintainer }}</td>
                  <td>{{ i.maintainerNum }}</td>
                  <td>
                    <i
                      class="fas fa-wifi iconstyle"
                      :id="i.factoryName + 'disconnect'"
                      :style="StatusFunction(i.statusResult.disconnect)"
                    ></i>
                    <i
                      class="fas fa-car-battery iconstyle"
                      :id="i.factoryName + 'invs'"
                      :style="StatusFunction(i.statusResult.invsTotalStatus)"
                    ></i>
                    <i
                      class="fas fa-bolt iconstyle meterIconStyle"
                      :id="i.factoryName + 'meter'"
                      :style="StatusFunction(i.statusResult.meter)"
                      @click="
                        showMeter(
                          i.factoryName,
                          StatusFunction(i.statusResult.meter)
                        )
                      "
                    ></i>
                    <i
                      class="fas fa-sun iconstyle"
                      :id="i.factoryName + 'irr'"
                      :style="StatusFunction(i.statusResult.irr)"
                    ></i>
                    <i
                      class="far fa-square iconstyle"
                      :id="i.factoryName + 'moduleTemp'"
                      :style="StatusFunction(i.statusResult.moduleTemp)"
                    ></i>
                    <i
                      class="fas fa-thermometer-half iconstyle"
                      :id="i.factoryName + 'envTemp'"
                      :style="StatusFunction(i.statusResult.envTemp)"
                    ></i>
                    <i
                      class="fas fa-wind iconstyle"
                      :id="i.factoryName + 'windSpeed'"
                      :style="StatusFunction(i.statusResult.windSpeed)"
                    ></i>
                    <i
                      class="fas fa-water iconstyle"
                      :id="i.factoryName + 'waterLevel'"
                      :style="StatusFunction(i.statusResult.waterLevel)"
                    ></i>
                  </td>
                  <td>{{ i.timestamp }}</td>
                  <td
                    @click.stop="
                      openSetting(
                        i.config,
                        i.factoryName,
                        i.docId,
                        i.defaultConfig
                      )
                    "
                    style="cursor: pointer"
                  >
                    <i class="fas fa-sliders-h"></i>
                  </td>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'disconnect'"
                    triggers="hover"
                    class="sss"
                  >
                    <template #title>資料蒐集器</template>
                    <div class="fontStyle">
                      {{ i.statusResult.disconnect ? "斷線" : "正常" }}
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'invs'"
                    triggers="hover"
                    class="sss"
                  >
                    <template #title>變流器</template>
                    <div style="" class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.invsTotalStatus &&
                          i.statusResult.invsTotalStatus.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.invsTotalStatus &&
                          i.statusResult.invsTotalStatus.length > 0
                        "
                      >
                        <p
                          v-for="(x, idx) in i.statusResult.invsTotalStatus"
                          :key="idx"
                        >
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'meter'"
                    triggers="hover"
                    class="sss"
                  >
                    <template #title>電表</template>
                    <div class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.meter &&
                          i.statusResult.meter.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.meter &&
                          i.statusResult.meter.length > 0
                        "
                      >
                        <p v-for="(x, idx) in i.statusResult.meter" :key="idx">
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'irr'"
                    triggers="hover"
                  >
                    <template #title>日照</template>
                    <div style="text-align: center" class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.irr && i.statusResult.irr.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.irr && i.statusResult.irr.length > 0
                        "
                      >
                        <p v-for="(x, idx) in i.statusResult.irr" :key="idx">
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'moduleTemp'"
                    triggers="hover"
                  >
                    <template #title>模溫</template>
                    <div class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.moduleTemp &&
                          i.statusResult.moduleTemp.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.moduleTemp &&
                          i.statusResult.moduleTemp.length > 0
                        "
                      >
                        <p
                          v-for="(x, idx) in i.statusResult.moduleTemp"
                          :key="idx"
                        >
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'envTemp'"
                    triggers="hover"
                  >
                    <template #title>環溫</template>
                    <div class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.envTemp &&
                          i.statusResult.envTemp.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.envTemp &&
                          i.statusResult.envTemp.length > 0
                        "
                      >
                        <p
                          v-for="(x, idx) in i.statusResult.envTemp"
                          :key="idx"
                        >
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'windSpeed'"
                    triggers="hover"
                  >
                    <template #title>風速</template>
                    <div class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.windSpeed &&
                          i.statusResult.windSpeed.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.windSpeed &&
                          i.statusResult.windSpeed.length > 0
                        "
                      >
                        <p
                          v-for="(x, idx) in i.statusResult.windSpeed"
                          :key="idx"
                        >
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                  <b-popover
                    placement="rightbottom"
                    :target="i.factoryName + 'waterLevel'"
                    triggers="hover"
                  >
                    <template #title>水位</template>
                    <div class="fontStyle">
                      <div
                        v-if="
                          i.statusResult.waterLevel &&
                          i.statusResult.waterLevel.length === 0
                        "
                      >
                        正常
                      </div>
                      <div
                        class="popoverStyle"
                        v-else-if="
                          i.statusResult.waterLevel &&
                          i.statusResult.waterLevel.length > 0
                        "
                      >
                        <p
                          v-for="(x, idx) in i.statusResult.waterLevel"
                          :key="idx"
                        >
                          {{ x.msg }}
                        </p>
                      </div>
                      <div v-else>無裝置或是未填設定值</div>
                    </div>
                  </b-popover>
                </tr>
              </table>
            </div>
          </b-overlay>
        </div>
      </b-row>
      <b-modal id="settingModal" size="xl" title="設定" hide-footer>
        <b-alert
          v-if="configExist"
          show
          variant="success"
          style="text-align: center; margin-top: 15px; font-weight: 700"
          >此案場尚未上傳設定值</b-alert
        >
        <div class="othertableStyle">
          <table>
            <tr>
              <th>資料異常之判定</th>
              <th>數值</th>
              <th>上限值</th>
              <th>下限值</th>
              <th>備註</th>
            </tr>
            <tr v-for="(i, idx) in settingArray" :key="idx">
              <td v-if="idx === 'invs'">
                <span style="margin-right: 5px">變流器</span>
                <select v-model="invSelect">
                  <option
                    v-for="(j, key, index) in i"
                    :key="index"
                    :value="key"
                  >
                    {{ transferConfig(key) }}
                  </option>
                </select>
                <!-- {{invSelect}} -->
              </td>
              <td v-else>{{ transferConfig(idx) }}</td>

              <td v-if="i[`${invSelect}`] && idx === 'invs'">
                <input
                  class="inputwidth"
                  type="text"
                  :value="i[`${invSelect}`].unit"
                  @input="i[`${invSelect}`].unit = $event.target.value"
                />
              </td>
              <td v-else>
                <input
                  class="inputwidth"
                  type="text"
                  :value="i.unit"
                  @input="i.unit = $event.target.value"
                />
              </td>

              <td v-if="i[`${invSelect}`] && idx === 'invs'">
                <input
                  class="inputwidth"
                  type="number"
                  :value="i[`${invSelect}`].max"
                  @input="i[`${invSelect}`].max = $event.target.value"
                />
              </td>
              <td v-else>
                <input
                  class="inputwidth"
                  type="number"
                  :value="i.max"
                  @input="i.max = Number($event.target.value)"
                />
              </td>

              <td v-if="i[`${invSelect}`] && idx === 'invs'">
                <input
                  class="inputwidth"
                  type="number"
                  :value="i[`${invSelect}`].min"
                  @input="i[`${invSelect}`].min = $event.target.value"
                />
              </td>
              <td v-else>
                <input
                  class="inputwidth"
                  type="number"
                  :value="i.min"
                  @input="i.min = Number($event.target.value)"
                />
              </td>

              <td v-if="i[`${invSelect}`] && idx === 'invs'">
                <input
                  class="inputwidth"
                  type="text"
                  :value="i[`${invSelect}`].note"
                  @input="i[`${invSelect}`].note = $event.target.value"
                />
              </td>
              <td v-else>
                <input
                  class="inputwidth"
                  type="text"
                  :value="i.note"
                  @input="i.note = $event.target.value"
                />
              </td>
            </tr>
          </table>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <button
            class="confirmButton"
            style="width: 80px; padding: 2px; margin: 8px"
            @click="upDateConfig(settingArray)"
          >
            上傳
          </button>
        </div>
      </b-modal>
      <b-modal
        id="meterModal"
        size="xl"
        :title="meterFactory + ' 電表資料'"
        hide-footer
        v-if="meterData"
      >
        <p>
          資料更新時間:
          {{
            DateTime.fromISO(meterData.timestamp)
              .minus({ hour: 8 })
              .toFormat("yyyy-MM-dd HH:mm:ss")
          }}
        </p>
        <div class="othertableStyle" style="display: flex">
          <table style="margin: 5px" v-if="this.meterData.etc['HV-meter']">
            <tr>
              <th colspan="2">HV-METER</th>
            </tr>
            <tr
              v-for="(i, idx) in Object.entries(this.meterData.etc['HV-meter'])"
              :key="idx"
            >
              <td>{{ i[0] }}</td>
              <td>{{ i[1] }}</td>
            </tr>
          </table>
          <table style="margin: 5px" v-if="this.meterData.etc['LV-meter']">
            <tr>
              <th colspan="2">LV-METER</th>
            </tr>
            <tr
              v-for="(i, idx) in Object.entries(this.meterData.etc['LV-meter'])"
              :key="idx"
            >
              <td>{{ i[0] }}</td>
              <td>{{ i[1] }}</td>
            </tr>
          </table>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BModal, BPopover, BAlert, BOverlay } from 'bootstrap-vue'
import {
  getRecentAlarmsApi,
  updateDeviceConfigApi,
  getLastEtcDataApi
} from '../api'
import { DateTime } from 'luxon'
export default {
  components: {
    BModal,
    BPopover,
    BAlert,
    BOverlay
  },
  data () {
    return {
      DateTime,
      meterFactory: '',
      meterData: null,
      filterSite: [],
      fakeSettingArray: [
        '(依)變流器flag',
        '電表',
        '日照',
        '模溫',
        '環溫',
        '風速',
        '水位'
      ],
      settingArray: {},
      invSelect: 'irr',
      recentalarmsData: [],
      updateFactory: '',
      updateDocID: '',
      filterTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          sort: true
        },
        {
          name: '案場編號',
          sortName: 'projectNumber',
          sort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          sort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          sort: true
        },
        {
          name: '工程師工號',
          sortName: 'maintainerNum',
          sort: true
        },
        {
          name: '連線時間',
          sortName: 'timestamp',
          sort: true
        }
      ],
      thTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          sort: true
        },
        {
          name: '案場編號',
          sortName: 'projectNumber',
          sort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          sort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          sort: true
        },
        {
          name: '工程師工號',
          sortName: 'maintainerNum',
          sort: true
        },
        {
          name: '裝置狀態',
          sortName: 'level',
          sort: false
        },
        {
          name: '連線時間',
          sortName: 'timestamp',
          sort: true
        }
      ],
      searchInput: '',
      selectValue: {
        name: '案場',
        sortName: 'factoryName',
        sort: true
      },
      configExist: true,
      loadingData: false
    }
  },
  computed: {
    ...mapState(['filterSiteArray']),
    factoryStatusFilterSearch () {
      this.recentalarmsData.forEach((x) => {
        if (x.timestamp === null) x.timestamp = ''
      })
      if (this.filterSite.length > 0) {
        return this.recentalarmsData.filter(
          (resources) =>
            resources[`${this.selectValue.sortName}`].match(this.searchInput) &&
            this.filterSite.includes(resources['county'])
        )
      }
      return this.recentalarmsData.filter((resources) =>
        resources[`${this.selectValue.sortName}`].match(this.searchInput)
      )
    },
    minHeight () {
      const height = this.loadingData ? 'min-height: 75vh' : 'min-height: auto'
      return height
    }
  },
  methods: {
    goMoxaPage () {
      window.open('https://dlm.thingsprocloud.com/login')
    },
    transferConfig (key) {
      if (key === 'invs') return '變流器'
      if (key === 'meter') return '電表'
      if (key === 'S40') return 'S40'
      if (key === 'irr') return '日照計'
      if (key === 'moduleTemp') return '模組溫度'
      if (key === 'envTemp') return '環溫'
      if (key === 'windSpeed') return '風速'
      if (key === 'waterLevel') return '水位'
      if (key === 'mppt') return 'mppt'
      if (key === 'eff') return '效率'
      if (key === 'eNetV') return '電網電壓'
      if (key === 'eNetFreq') return '電網頻率'
      if (key === 'temp') return '溫度'
      return key
    },
    StatusFunction (obj) {
      const gray = 'color: #787878; opacity: 0.6'
      const red = 'color: #FF2D2D'
      const yellow = 'color: #ffe606'
      const green = 'color: green'
      if (obj === null) return gray
      if (typeof obj === 'boolean') {
        if (obj) {
          return red
        } else {
          return green
        }
      }
      if (obj.length > 0) {
        const noAlert = obj.every((x) => x.noAlert === true)
        if (noAlert) {
          return green
        } else {
          return yellow
        }
      } else {
        return green // fix meter
      }
    },
    alarmsLevel (arr) {
      arr.forEach((x) => {
        const vlevel = 9
        x.level = null
        for (const [index, [key, value]] of Object.entries(
          Object.entries(x.statusResult)
        )) {
          if (key === 'disconnect' && value) x.level = Math.pow(2, 10)
          if (
            value &&
            value.length > 0 &&
            !value.every((y) => y.noAlert === true)
          ) {
            let levelIndex = index
            if (key === 'invsTotalStatus') levelIndex = 1
            x.level = x.level
              ? x.level + Math.pow(2, vlevel - levelIndex)
              : Math.pow(2, vlevel - levelIndex)
          }
          if (value === null) x.level++
        }
        if (!x.level) x.level = 0
      })
      return arr
    },
    openSetting (obj, factoryName, docId, configObj) {
      this.configExist = true
      const defaultConfig = configObj
      this.settingArray = obj !== undefined ? obj : defaultConfig
      // this.invSelect = obj.invs.irr
      this.configExist =
        obj !== undefined ? !this.configExist : this.configExist
      this.updateFactory = factoryName
      this.updateDocID = docId
      this.$bvModal.show('settingModal')
    },
    sortHistoryData (str, idx) {
      this.recentalarmsData = this.recentalarmsData.sort((a, b) => {
        if (a[`${str}`] === null) a[`${str}`] = ''
        if (b[`${str}`] === null) b[`${str}`] = ''
        if (a[`${str}`] > b[`${str}`]) return 1
        if (a[`${str}`] < b[`${str}`]) return -1
        return 0
      })
      if (!this.thTitle[idx].sort) {
        this.recentalarmsData = this.recentalarmsData.reverse()
      }
      this.thTitle[idx].sort = !this.thTitle[idx].sort
    },
    sortAlertMsg (array) {
      array.forEach((x) => {
        if (x.statusResult.invsTotalStatus !== null) {
          const alertArray = x.statusResult.invsTotalStatus.filter(
            (y) => y.noAlert === false
          )
          const noAlertArray = x.statusResult.invsTotalStatus.filter(
            (y) => y.noAlert === true
          )
          const sortArray = alertArray.concat(noAlertArray)
          x.statusResult.invsTotalStatus = sortArray
        }
      })
      return array
    },
    async getRecentalarms () {
      this.loadingData = true
      this.recentalarmsData = []
      const recentalarms = await getRecentAlarmsApi()
      if (recentalarms?.data?.success) {
        this.rebuildInvsResult(recentalarms.data.data)
        this.recentalarmsData = this.alarmsLevel(recentalarms.data.data)
        this.recentalarmsData = this.sortAlertMsg(this.recentalarmsData)
        this.thTitle[5].sort = false
        this.sortHistoryData('level', 5)
      }
      this.loadingData = false
    },
    rebuildInvsResult (array) {
      array.forEach((x) => {
        if (x.statusResult.invs !== null) {
          const invsObjArray = Object.values(x.statusResult.invs)
          let resultArray = []
          invsObjArray.forEach((y) => {
            if (y !== null) {
              resultArray = resultArray.concat(y)
            }
          })
          x.statusResult.invsTotalStatus = resultArray
        } else {
          x.statusResult.invsTotalStatus = null
        }
      })
    },
    async upDateConfig (arr) {
      const deviceConfig = arr
      const upDateConfig = await updateDeviceConfigApi(
        this.updateFactory,
        this.updateDocID,
        deviceConfig
      )
      if (upDateConfig?.data?.success) {
        this.$swal('上傳成功')
      }
    },
    async showMeter (factoryName, meterExist) {
      if (meterExist === 'color: #787878; opacity: 0.6') return
      this.meterFactory = factoryName
      await this.getlastetcdata(factoryName)
      this.$bvModal.show('meterModal')
    },
    async getlastetcdata (factoryName) {
      const lastEtcData = await getLastEtcDataApi(factoryName)
      if (lastEtcData?.data?.success) {
        this.meterData = lastEtcData.data.data
      }
    }
  },
  created () {
    this.getRecentalarms()
  },
  mounted () {}
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/global.scss";
@import "../assets/scss/customVariables.scss";
.meterIconStyle {
  cursor: pointer;
}
.meterTableStyle {
  td,
  th {
    height: 30px;
  }
}
.fontStyle {
  font-size: 16px;
  font-weight: 700;
  p {
    padding: 5px 0px;
    margin: 0;
  }
}
.popoverStyle {
  max-height: 300px;
  overflow: auto;
}
.sort {
  position: relative;
  cursor: pointer;
}
.sort::after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  top: calc(50% - 9px);
  width: 20px;
  height: 20px;
}
.down::after {
  background: #7dc6cd url(../image/sortBtn02.png);
  background-size: 100%;
}
.up::after {
  background: #7dc6cd url(../image/sortBtn03.png);
  background-size: 100%;
}
.inputwidth {
  width: 100px;
}
.normal {
  color: rgb(109, 139, 109);
}
table {
  width: 100% !important;
}

table th {
  color: aliceblue;
  font-size: 16px;
  text-align: center;
}

table td {
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
table td:nth-child(3) {
  max-width: 250px;
}
table td:nth-child(4) {
  max-width: 250px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
.iconstyle {
  font-size: 25px;
  color: gray;
  padding: 7px 13px;
}
.rwdTable {
  min-width: 1200px;
}
// @media screen and (max-width: 520px) {
//   .rwdTable {
//     min-width: 1500px;
//   }
// }
</style>
<style lang="css">
.popover {
  max-width: max-content;
}
</style>
